import React, { useCallback, useMemo } from 'react';
import { ButtonSize, IconButton, IconButtonThemes, Popover } from 'wix-ui-tpa';
import { CloseSmall } from '@wix/wix-ui-icons-common/on-stage';
import { st, classes } from './MinimizedChat.st.css';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  selectMessages,
  selectSettings,
  selectLayout,
} from '../../../../../features';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import { icons, iconsDict } from '../../../Settings/components';
import { expandWidget } from '../../../../../features/layout/layoutThunks';
import { setRead as setReadThunk } from '../../../../../features/messages/messagesThunks';
import { MinimizedChatDataHooks } from './MinimizedChatDataHooks';

export const MinimizedChat = ({
  setRef,
}: {
  setRef: (ref: HTMLDivElement | null) => void;
}) => {
  const settings = useSettings();
  const dispatch = useAppDispatch();

  const unread = useAppSelector(selectMessages.hasUnread);
  const lastMessage = useAppSelector(selectMessages.lastAnswer);
  const isOnline = useAppSelector(selectSettings.isOnline);
  const wasOpened = useAppSelector(selectLayout.wasOpened);

  const expand = useCallback(() => {
    dispatch(expandWidget());
  }, [dispatch]);

  const setRead = useCallback(() => {
    dispatch(setReadThunk());
  }, [dispatch]);

  const stylesConfig = useMemo(() => {
    const pos =
      settings.get(settingsParams.minimizedButtonPosition) ?? 'floating';
    const display =
      settings.get(settingsParams.minimizedButtonDisplay) ?? 'textIcon';

    return {
      isLeft: settings.get(settingsParams.layout) === 'left',
      isSticky: pos === 'sticky',
      showIcon: pos === 'icon' || display === 'icon' || display === 'textIcon',
      showText:
        pos !== 'icon' && (display === 'text' || display === 'textIcon'),
      hasShadow: settings.get(settingsParams.minimizedButtonShadow) === true,
      icon: settings.get(settingsParams.minimizedButtonIcon) ?? icons[0].value,
    };
  }, [settings]);

  const popoverCase = unread && Boolean(lastMessage);

  return (
    <div
      className={st(classes.root, {
        left: stylesConfig.isLeft,
        sticky: stylesConfig.isSticky,
        nudge:
          settings.get(settingsParams.minimizedButtonNudgeAnimation) === true &&
          (!wasOpened || popoverCase),
      })}
      ref={setRef}
      data-hook={MinimizedChatDataHooks.ROOT}
    >
      <Popover
        shown={popoverCase}
        placement="top"
        showArrow={false}
        minWidth={220}
        contentClassName={st(classes.popover)}
      >
        <Popover.Element>
          <button
            onClick={expand}
            className={st(classes.button, {
              sticky: stylesConfig.isSticky,
              hasShadow: stylesConfig.hasShadow,
            })}
            data-hook={MinimizedChatDataHooks.BUTTON}
          >
            {stylesConfig.showIcon && iconsDict[stylesConfig.icon]}
            {stylesConfig.showText && (
              <div className={st(classes.text)}>
                {settings.get(
                  isOnline
                    ? settingsParams.textButtonOnline
                    : settingsParams.textButtonOffline,
                )}
              </div>
            )}
          </button>
        </Popover.Element>
        <Popover.Content>
          {lastMessage?.text}
          <IconButton
            className={st(classes.closeButton)}
            theme={IconButtonThemes.Box}
            size={ButtonSize.small}
            icon={<CloseSmall />}
            onClick={setRead}
          />
        </Popover.Content>
      </Popover>
    </div>
  );
};
