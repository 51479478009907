import React, { useMemo } from 'react';
import { Button, ButtonPriority, Card, Text } from 'wix-ui-tpa';
import { classes, st } from './Product.st.css';
import { ProductGallery } from '../ProductGallery';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ChatCard } from '../ChatCard';
import { PayloadCmpProps } from '../ChatMessagePayload/types';
import { useSiteNavigation } from '../../contexts';

type ProductProps = PayloadCmpProps & {
  images?: string[];
  title?: string;
  subtitle?: string;
  description?: string;
  price?: string;
  link?: string;
};

export const Product: React.FC<
  ProductProps & React.HTMLAttributes<HTMLElement>
> = ({
  isSmall,
  images,
  title,
  subtitle,
  description,
  price,
  link,
  ...rootElementAttrs
}) => {
  const [t] = useTranslation();
  const hasInfoBlock = useMemo(
    () => Boolean(title || subtitle || description || price),
    [description, price, subtitle, title],
  );
  const { navigateToUrl } = useSiteNavigation();

  return (
    <ChatCard
      {...rootElementAttrs}
      className={`${st(classes.root)} ${rootElementAttrs.className}`}
      cardClassName={st(classes.card)}
      isSmall={!!isSmall}
    >
      <Card.Container className={st(classes.section, classes.sectionGallery)}>
        <ProductGallery images={images ?? []} isSmall={Boolean(isSmall)} />
      </Card.Container>

      {hasInfoBlock && (
        <Card.Container
          className={st(classes.section, classes.sectionInfoBlock)}
        >
          {title && <Text className={st(classes.title)}>{title}</Text>}
          {subtitle && (
            <Text className={st(classes.description)}>{subtitle}</Text>
          )}
          {description && (
            <Text className={st(classes.description)}>{description}</Text>
          )}
          {price && <Text className={st(classes.description)}>{price}</Text>}
        </Card.Container>
      )}

      {link && (
        <Card.Container className={st(classes.section, classes.sectionLink)}>
          <Button
            upgrade
            onClick={() => navigateToUrl(link!)}
            priority={ButtonPriority.primary}
            className={st(classes.button)}
            fullWidth
          >
            {t('app.widget.product.viewItem')}
          </Button>
        </Card.Container>
      )}
    </ChatCard>
  );
};
