import React from 'react';
import { Button, ButtonPriority, ButtonSize } from 'wix-ui-tpa';
import { PayloadCmpProps } from '../ChatMessagePayload/types';
import { classes, st } from './PagePayload.st.css';
import { useSiteNavigation } from '../../contexts';

type PagePayloadProps = PayloadCmpProps & {
  image?: string;
  title?: string;
  link?: string;
};

export const PagePayload: React.FC<
  PagePayloadProps & React.HTMLAttributes<HTMLElement>
> = ({ isSmall, image, title, link, ...rootElementAttrs }) => {
  const { navigateToUrl } = useSiteNavigation();

  if (!link) {
    console.error(`Page payload with no link!`, { title, link });
    return null;
  }
  // TODO: make PrimaryButton and SecondaryButton separate components.
  return (
    <div className={`${st(classes.root)} ${rootElementAttrs.className}`}>
      <Button
        upgrade
        priority={ButtonPriority.secondary}
        onClick={() => navigateToUrl(link)}
        className={st(classes.button)}
        size={ButtonSize.tiny}
      >
        {title}
      </Button>
    </div>
  );
};
