import React, { useCallback, useEffect, useRef } from 'react';
import { Container } from '../Container';
import { Header } from '../Header';
import { Input } from '../Input';
import { useOnEditorSettingsEvent } from '../../contexts';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  addMessage,
  EditorScrollTarget,
  selectMessages,
} from '../../../../../features';
import { ChatMessage } from '../ChatMessage';
import { st, classes, cssStates } from './Chat.st.css';
import { Loader } from '../Loader';
import {
  AssistantChatScrollReversed,
  AssistantChatScrollReversedRef,
  FirstMessagePositioning,
} from 'assistant-chat-scroll';

import './imports.global.scss';

import { useHistoryLoader } from './useHistoryLoader';
import { useIntroSequence } from './useIntroSequence';
import { DummyMessageNamedIndex } from '../../../../../features/messages/dummyMessages';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useDebouncedCallback } from '../../../../../hooks';
import { MessageType } from '@wix/ambassador-innovation-widget-v1-message/types';

export const ScrollLayoutStateToDummyIndex: Record<EditorScrollTarget, number> =
  {
    end: DummyMessageNamedIndex.END,
    UoU: DummyMessageNamedIndex.UOU,
    gallery: DummyMessageNamedIndex.GALLERY,
    page: DummyMessageNamedIndex.PAGE,
  };

export const Chat: React.FC = () => {
  const ref = useRef<AssistantChatScrollReversedRef | null>(null);
  const dispatch = useAppDispatch();
  const messages = useAppSelector(selectMessages.listWithoutSubmittedForms);
  const isTyping = useAppSelector(selectMessages.isTyping);
  const isLoaded = useAppSelector(selectMessages.isLoaded);
  const loadingFirstPage = useAppSelector(selectMessages.loadingFirstPage);
  const loadOlderMessages = useHistoryLoader();
  const { isEditor } = useEnvironment();

  const scrollToTarget = useDebouncedCallback(
    (scrollTargetName: EditorScrollTarget) => {
      ref?.current?.scrollToIndex(
        ScrollLayoutStateToDummyIndex[scrollTargetName] ??
          ScrollLayoutStateToDummyIndex.end,
        { animate: true, align: 'end' },
      );
    },
    [],
  );

  const { isSequenceFulfilled } = useIntroSequence();

  const [value, setValue] = React.useState('');

  const onInputChange = useCallback((newValue: string) => {
    setValue(newValue);
    ref.current?.scrollToIndex(-1, { align: 'start', animate: true });
  }, []);

  const onSubmit = useCallback(() => {
    if (value.trim().length) {
      dispatch(addMessage({ text: value }));
      setValue('');
    }
  }, [dispatch, value]);

  useOnEditorSettingsEvent(
    'scroll',
    (scrollTargetName: EditorScrollTarget) => {
      if (!isEditor || !scrollTargetName || !ref?.current) {
        return;
      }
      scrollToTarget(scrollTargetName);
    },
    [isEditor, ref],
  );

  // Form scroll to beginning
  useEffect(() => {
    if (messages.length) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.messageType === MessageType.CONTACT_FORM) {
        ref.current?.scrollToIndex(-1, { align: 'end', animate: true });
      }
    }
  }, [messages]);

  return (
    <Container>
      <Header />
      <div className={st(classes.container)}>
        {loadingFirstPage && <Loader />}
        <div
          className={st(classes.wrapper, cssStates({ isVisible: isLoaded }))}
        >
          <AssistantChatScrollReversed
            ref={ref}
            paddingStart={20}
            paddingStartFirstMessage={8}
            paddingEnd={8}
            estimateSizePx={50}
            scrollLoadHistoryOffsetPx={5}
            overscan={10}
            style={{ width: '100%', height: '100%' }}
            scrollLoadHistoryWorker={loadOlderMessages}
            loader={<Loader />}
            scrollToLastOnAppend={!isEditor}
            firstMessagePosition={FirstMessagePositioning.start}
          >
            {messages.map((m, i) => (
              <ChatMessage
                message={m}
                last={i === messages.length - 1}
                key={m.id}
              />
            ))}
            {isTyping && <Loader />}
          </AssistantChatScrollReversed>
        </div>
      </div>
      <Input
        value={value}
        onChange={onInputChange}
        onSubmit={onSubmit}
        disabled={!isSequenceFulfilled}
      />
    </Container>
  );
};
