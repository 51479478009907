import React, { useEffect, useState } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import {
  ControllerProps,
  ControllerValidation,
} from './components/ControllerValidation';

export type AIAssistantWidgetProps = WidgetProps<Partial<ControllerProps>>;

export const Widget = (props: AIAssistantWidgetProps) => {
  const [result, setResult] = useState<JSX.Element | null>(null);

  // Hack for Widget didn't load
  useEffect(() => {
    setResult(
      <ControllerValidation {...(props as WidgetProps<ControllerProps>)} />,
    );
  }, [props]);

  return result;
};
