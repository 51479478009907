import React, { useMemo } from 'react';
import { st, classes, cssStates } from './Container.st.css';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import settingsParams from '../../../settingsParams';
import { ContainerDataHooks } from './ContainerDataHooks';

type ContainerProps = {
  children: React.ReactNode;
};

export const Container = ({ children }: ContainerProps) => {
  const { isEditor, isMobile } = useEnvironment();
  const settings = useSettings();

  const isLeftLayout = useMemo(
    () => settings.get(settingsParams.layout) === 'left',
    [settings],
  );

  return (
    <div
      className={st(
        classes.root,
        cssStates({
          left: isLeftLayout,
          isEditor,
          isMobile,
        }),
      )}
      // TODO: check why settings `top` doesn't work in `st.css` file
      style={isEditor || isMobile ? {} : { top: 'var(--wix-ads-height)' }}
      data-hook={ContainerDataHooks.ROOT}
    >
      <div
        className={st(
          classes.wrapper,
          classes.overscrollHack,
          cssStates({
            hasShadow: settings.get(settingsParams.applyShadow),
          }),
        )}
      >
        {children}
      </div>
    </div>
  );
};
