import React from 'react';
import { Message, selectFlags } from '../../../../../features';
import { Card, Badge, BadgePriority } from 'wix-ui-tpa';
import { st, classes, cssStates } from './ChatMessage.st.css';
import {
  Sender,
  MessageType,
} from '@wix/ambassador-innovation-widget-v1-message/types';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import { AIAvatarIcon, AvatarIcon } from '../svg';
import { ChatMessageWrapper } from '../ChatMessageWrapper';
import { ChatMessagePayload } from '../ChatMessagePayload';
import { ContactForm } from '../ContactForm';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ContactFormDataHooks } from '../ContactForm/ContactFormDataHooks';
import { useAppSelector } from '../../../../../store';

export interface ChatMessageProps {
  message?: Message;
  last?: boolean;
}

export const ChatMessage = ({ message, last = false }: ChatMessageProps) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const isContact = useAppSelector(selectFlags.isContact);

  const isStuffMessage = message?.sender !== Sender.UOU;
  return (
    <ChatMessageWrapper className={last ? st(classes.lastMessage) : ''}>
      <Card
        className={st(
          classes.root,
          cssStates({
            aiAnswer: isStuffMessage,
            userQuestion: !isStuffMessage,
            applyAiAnswerShadow:
              isStuffMessage &&
              settings.get(settingsParams.applyAssistantMessageShadow),
          }),
        )}
      >
        <Card.Container className={st(classes.container)}>
          <div className={st(classes.icon)}>
            {isStuffMessage ? <AIAvatarIcon /> : <AvatarIcon />}
          </div>
          <div className={st(classes.content)}>
            {message?.sender === Sender.USER && (
              <Badge priority={BadgePriority.secondary}>
                {t('app.widget.badge.stuffRespond')}
              </Badge>
            )}
            {message && ( // TODO: make better matching message types
              <>
                {message.messageType === MessageType.CONTACT_FORM_SUBMITTED && (
                  <p data-hook={ContactFormDataHooks.SUBMITTED_MESSAGE}>
                    {t('app.widget.form.submitResponse')}
                  </p>
                )}

                {!!message.text?.trim().length && <p>{message.text}</p>}
                <ChatMessagePayload message={message} />
                {message.suffixText && <p>{message.suffixText}</p>}

                {message.messageType === MessageType.CONTACT_FORM &&
                  !isContact && <ContactForm formId={message.id} />}
              </>
            )}
            {isStuffMessage && <div className={st(classes.cardBottomSpacer)} />}
          </div>
        </Card.Container>
      </Card>
    </ChatMessageWrapper>
  );
};
